import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '@/router'

import appModule from '@/store/modules/app.js';
import talentProfileModule from '@/store/modules/talentProfile.js';
import jobModule from '@/store/modules/job.js';

export const activeNamespaces = {
  app: 'app',
  talentProfile: 'talentProfile',
  job: 'job'
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [activeNamespaces.app]
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    [activeNamespaces.app]: appModule,
    [activeNamespaces.talentProfile]: talentProfileModule,
    [activeNamespaces.job]: jobModule
  },
  plugins: [vuexLocal.plugin]
})
