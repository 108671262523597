import axios from "axios";

export const resume = axios.create({
  baseURL: "https://api.airtable.com/v0",
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AIR_TABLE_TOKEN}`,
  },
});

export const talentProfileAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API}/talent`,
  endpoints: {
    getProfile: "/",
    patchProfile: "/",
    addProfessionalExperience: '/professionalExperience/',
    updateProfessionalExperience: '/professionalExperience/',
    addQualification: '/qualification/',
    updateQualification: '/qualification/',
    getSkills: '/skills',
    getTitles: '/titles',
    getCompanies: '/companies',
  },
});

export const jobAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API}/job`,
  endpoints: {
    getJobs: "/",
    getJobDetails: "/details/",
    updateJob: "/",
    addJob: "/",
    createJobAndInviteTalents: "/create-invite/"
  },
});

export const applicantAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API}/applicant`,
  endpoints: {
    addApplicants: "/",
    putApplicant: "/",
  },
});

export const talentProfilePhotoAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API}/talent`,
  endpoints: {
    uploadPhoto: (id) => `/${id}/photo`,
  },
});

export const placesAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API}/talent/location`,
  endpoints: {
    placeDetails: '/details',
    autocomplete: '/autocomplete'
  }
})