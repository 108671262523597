<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 5L8 8" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M8 11L8 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M4.63135 1.11611C4.86578 0.881707 5.18373 0.750031 5.51525 0.750051L10.4856 0.75036C10.8172 0.75038 11.1351 0.882096 11.3695 1.11653L14.8839 4.63135C15.1183 4.86578 15.25 5.18373 15.2499 5.51525L15.2496 10.4856C15.2496 10.8172 15.1179 11.1351 14.8835 11.3695L11.3687 14.8839C11.1342 15.1183 10.8163 15.25 10.4847 15.2499L5.51435 15.2496C5.18283 15.2496 4.8649 15.1179 4.63049 14.8835L1.11611 11.3687C0.881707 11.1342 0.750031 10.8163 0.750051 10.4847L0.75036 5.51435C0.75038 5.18283 0.882096 4.8649 1.11653 4.63049L4.63135 1.11611Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    name: 'WarningSquared'
}
</script>