<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.27603 6L11.7421 1.5339C11.9085 1.36411 12.0012 1.13551 12 0.897777C11.9988 0.660045 11.9038 0.432392 11.7357 0.264288C11.5676 0.0961835 11.34 0.00121222 11.1022 1.15242e-05C10.8645 -0.00118917 10.6359 0.0914776 10.4661 0.257875L6 4.72397L1.5339 0.257875C1.36411 0.0914776 1.13551 -0.00118917 0.897777 1.15242e-05C0.660045 0.00121222 0.432392 0.0961835 0.264288 0.264288C0.0961835 0.432392 0.00121222 0.660045 1.15242e-05 0.897777C-0.00118917 1.13551 0.0914776 1.36411 0.257875 1.5339L4.72397 6L0.257875 10.4661C0.0914776 10.6359 -0.00118917 10.8645 1.15242e-05 11.1022C0.00121222 11.34 0.0961835 11.5676 0.264288 11.7357C0.432392 11.9038 0.660045 11.9988 0.897777 12C1.13551 12.0012 1.36411 11.9085 1.5339 11.7421L6 7.27603L10.4661 11.7421C10.6359 11.9085 10.8645 12.0012 11.1022 12C11.34 11.9988 11.5676 11.9038 11.7357 11.7357C11.9038 11.5676 11.9988 11.34 12 11.1022C12.0012 10.8645 11.9085 10.6359 11.7421 10.4661L7.27603 6Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
