import axios from 'axios'
import router from '@/router'
import { v4 as uuidv4 } from 'uuid';

const auth = axios.create({
    baseURL: process.env.VUE_APP_API
  });

const state = {
    token: '',
    talentUserId: null,
    talentToken: '',
    talentResultCount: null,
    user: null,
    lastTime: new Date(),
    viewedTalentIds: [],
    forgotEmail: '',
    resetPasswordToken: '',
    cannyToken: null,
    talenResumeUpload: null,
    placesAPISessionToken: null,
    filtersSidebarExpanded: false,
    filtersCount: 0,
    tokenBalance: 500,
    filterOptions: {},
  };

const getters = {
    isTalentViewed: ({ viewedTalentIds }) => (id) => viewedTalentIds.includes(id),
    talentToken: ({ talentToken }) => talentToken,
    talentUserId: ({ talentUserId }) => talentUserId,
    lastTime: ({ lastTime }) => new Date(lastTime),
    token: ({ token }) => token,
    cannyToken: ({ cannyToken }) => cannyToken,
    talentResultCount: ({ talentResultCount }) => talentResultCount,
    talenResumeUpload: ({talenResumeUpload}) => talenResumeUpload,
    filtersSidebarExpanded: ({filtersSidebarExpanded}) => filtersSidebarExpanded,
    filtersCount: ({filtersCount}) => filtersCount,
    user: ({ user }) => {
      if (!user) {
        return null
      }

      const { first_name, last_name, email } = user
      let avatar = ''
      let fullName = ''

      if (first_name && last_name) {
        fullName = `${first_name} ${last_name}`
      } else if (first_name) {
        fullName = first_name
      } else if (last_name) {
        fullName = last_name
      }

      avatar = fullName || email

      return { ...user, avatar, fullName }
    },
    placesAPISessionToken: ({ placesAPISessionToken }) => placesAPISessionToken,
    tokenBalance: ({ tokenBalance }) => tokenBalance,
    filterOptions: ({ filterOptions }) => filterOptions,
  };

const mutations = {
    INSERT_TALENT_ID_INTO_VIEWED_LIST (state, payload) {
      if (state.viewedTalentIds.includes(payload)) {
        return
      }

      state.viewedTalentIds.push(payload)
    },
    SET_LAST_TIME (state, payload) {
      const lastTime = new Date(state.lastTime)
      const millisecondsDiff = payload.getTime() - lastTime.getTime()
      const daysDiff = Math.round(millisecondsDiff / (24 * 60 * 60 * 60))

      if (daysDiff < 1) {
        return
      }

      state.lastTime = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_TALENT_TOKEN (state, payload) {
      state.talentToken = payload
    },
    SET_TALENT_USER_ID (state, payload) {
      state.talentUserId = payload
    },
    SET_CANNY_TOKEN (state, payload) {
      state.cannyToken = payload
    },
    SET_TALENT_RESULT_COUNT (state, payload) {
      state.talentResultCount = payload
    },
    SET_TALENT_RESUME_UPLOAD (state, payload) {
      state.talenResumeUpload = payload
    },
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_EMAIL_FOR_FORGOT_PASSWORD(state, payload) {
      state.forgotEmail = payload
    },
    SET_RESET_PASSWORD_TOKEN(state, payload) {
      state.resetPasswordToken = payload
    },
    SET_PLACES_API_SESSION_TOKEN(state, payload) {
      state.placesAPISessionToken = payload;
    },
    SET_FILTERS_SIDEBAR_EXPANDED(state, payload) {
      state.filtersSidebarExpanded = payload;
    },
    SET_FILTERS_COUNT(state, payload) {
      state.filtersCount = payload;
    },
    SET_TOKEN_BALANCE (state, payload) {
      state.tokenBalance = payload
    },
    SET_FILTER_OPTIONS (state, payload) {
      state.filterOptions = payload
    },
  };

const actions = {
    async FETCH_USER ({ commit, state }) {
     await auth.get(`/user/${state.user._id}`, { headers: { Authorization: `Bearer ${state.token}` } })
       .then(({ data }) => {
         commit('SET_USER', data)
       })
       .catch(() => {
         commit('SET_USER', null)
       })
   },
   SET_LAST_TIME ({ commit }) {
     commit('SET_LAST_TIME', new Date())
   },
   SET_TOKEN ({ commit }, payload) {
     commit('SET_TOKEN', payload)
   },
   SET_TALENT_TOKEN({ commit }, payload) {
    commit('SET_TALENT_TOKEN', payload)
   },
   SET_TALENT_USER_ID({ commit }, payload) {
    commit('SET_TALENT_USER_ID', payload)
   },
   SET_CANNY_TOKEN ({ commit }, payload) {
     commit('SET_CANNY_TOKEN', payload)
   },
   SET_TALENT_RESULT_COUNT ({ commit }, payload) {
     commit('SET_TALENT_RESULT_COUNT', payload)
   },
   SET_TALENT_RESUME_UPLOAD ({ commit }, payload) {
     commit('SET_TALENT_RESUME_UPLOAD', payload)
   },
   SET_EMAIL_FOR_FORGOT_PASSWORD ({ commit }, payload) {
     commit('SET_EMAIL_FOR_FORGOT_PASSWORD', payload)
   },
   SET_RESET_PASSWORD_TOKEN ({ commit }, payload) {
     commit('SET_RESET_PASSWORD_TOKEN', payload)
   },
   SET_USER ({ commit }, payload) {
     commit('SET_USER', payload)
   },
   SIGN_OUT ({ commit }) {
     commit('SET_USER', null)

     router.push({ name: 'signin' })
   },
   SIGN_OUT_ON_PAYMENT_SUCCESS ({ commit }) {
     commit('SET_USER', null)

     router.push({ name: 'signup', query: { payment: 'success' } })
   },
   UPDATE_USER ({ dispatch, getters }, payload) {
     const { token, user } = getters

     if (!token || !user) {
       return
     }

     return new Promise((resolve, reject) => {
       auth.put(`/user/${user._id}`, payload, { headers: { Authorization: `Bearer ${token}` } })
         .then(({ data }) => {
           const { token, updatedUser } = data

           dispatch('SET_TOKEN', token)
           dispatch('SET_USER', updatedUser)

           return resolve(updatedUser)
         })
         .catch((ex) => {
           dispatch('SET_TOKEN', '')
           dispatch('SET_USER', null)

           return reject(ex)
         })
     })
   },
   VIEWED_TALENT ({ commit }, payload) {
     commit('INSERT_TALENT_ID_INTO_VIEWED_LIST', payload)
   },
   GENERATE_PLACES_API_SESSION_TOKEN({ commit }) {
     commit('SET_PLACES_API_SESSION_TOKEN', uuidv4())
   },
   INVALIDATE_PLACES_API_SESSION_TOKEN({ commit }) {
     commit('SET_PLACES_API_SESSION_TOKEN', null)
   },
   SET_FILTERS_SIDEBAR_EXPANDED({ commit }, payload) {
      commit('SET_FILTERS_SIDEBAR_EXPANDED', payload)
   },
   SET_FILTERS_COUNT({ commit }, payload) {
    commit('SET_FILTERS_COUNT', payload);
   },
   SET_TOKEN_BALANCE ({ commit }, payload) {
    commit('SET_TOKEN_BALANCE', payload)
  },
  SET_FILTER_OPTIONS ({ commit }, payload) {
    commit('SET_FILTER_OPTIONS', payload)
  },
 };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
