import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtm from "@gtm-support/vue2-gtm";
import axios from "axios";
import store from "./store/index";
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Notifications from "vue-notification";
import vueClickOutsideElement from "vue-click-outside-element";
import VueLoadmore from "vuejs-loadmore";
import JSZip from "jszip";
import VueLazyload from "vue-lazyload";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/main.css";
import { init as initFullStory, FullStory } from "@fullstory/browser";
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min";

import ToastFactory from "@/components/ToastFactory.vue";
import { makeToast, GlobalEventEmitter } from "@/utils/GlobalEventEmitter";

import "vuejs-clipper/dist/vuejs-clipper.css";

Vue.use(VueRx);
Vue.use(VuejsClipper);
import { resume, talentProfileAPI, placesAPI } from '@/api.js';

// eslint-disable-next-line no-undef
Vue.prototype.$Pendo = pendo;

const base = axios.create({
  baseURL: process.env.VUE_APP_API,
});

if (process.env.VUE_APP_ENV == "prod") {
  initFullStory({ orgId: "o-1Q249R-na1" });
  Vue.prototype.$FullStory = FullStory;
}

Vue.component('toast-factory', ToastFactory);

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(vueClickOutsideElement);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);
Vue.use(VueLoadmore, {
  lang: "en-US",
});
Vue.use(Vuetify);
Vue.use(VueLazyload);
Vue.use(JSZip);

Vue.component('toast-factory', ToastFactory);

Vue.prototype.$http = resume;
Vue.prototype.$auth = base;
Vue.prototype.$talentProfileAPI = talentProfileAPI;
Vue.prototype.$placesAPI = placesAPI;
Vue.prototype.$GlobalEventEmitter = GlobalEventEmitter;
Vue.prototype.$toast = makeToast;

Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: "GTM-P372XKJ",
  vueRouter: router,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");